import React, { useEffect } from "react";
import "./assets/style/App.sass";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoutes from "./helper/PrivateRoutes.js";
import Login from "./components/login";
//import Register from "./components/Register";
import AudioRecordView from "./components/user-audioRecording";
import CoordinatorUserList from "./components/cordinator-userlist";
import CoordinatorUserFileList from "./components/coordinator-userfilelist";
import CoordinatorFileList from "./components/coordinator-filelist";
import AdminCoordinatorList from "./components/admin-coordinator-list";
import AdminSupervisorList from "./components/admin-supervisor-list";
import AdminQcUserList from "./components/admin-qcuser-list";
import AdminFileList from "./components/admin-filelist";
import AdminBulkFileReject from "./components/admin-bulkrejectfiles";
import SupervisorFileList from "./components/supervisor-filelist";
import SupervisorUserList from "./components/supervisor-userlist";
import SupervisorUserFileList from "./components/supervisor-userfilelist";
import SupervisorCoordinatorList from "./components/supervisor-coordinator-list";
import UploadImages from "./components/admin-Imageupload";
import AddRate from "./components/admin-addRate";
import AllUsers from "./components/admin-all-users";
import AdminTeamleadList from "./components/admin-teamlead-list";
import ManagerFileList from "./components/manager-filelist";
import ManagerUserList from "./components/manager-user-list";
import TeamLeadFileList from "./components/teamlead-filelist";
import TeamLeadCoordinatorList from "./components/teamlead-coordinator";
import TeamLeadSupervisorList from "./components/teamlead-supervisor-list";
import QcUserList from "./components/qc-userlist";
import QcFileList from "./components/qc-filelist";
import QcUserFileList from "./components/qc-userfilelist";
import QcOldUserList from "./components/qc-old-userlist.jsx";
import QcOldUserFileList from "./components/qc-old-userfilelist.jsx";
import QcUserSampleFileList from "./components/qc-usersamplefilelist";
import QcInterPairCheck from "./components/qc-interpaircheck";
import QcRejectedLangMismatchFiles from "./components/qc-langmismatchfilelist";
import QcRejectedLangMismatchUserList from "./components/qc-langmismatchuserlist";
import QcRejectedGenderMismatchUserList from "./components/qc-gendermismatchuserlist";
import QcRejectedGenderMismatchFiles from "./components/qc-gendermismatchfilelist";
import QcRejectedNoisyUserList from "./components/qc-noisyuserlist";
import QcRejectedNoisyFiles from "./components/qc-noisyfilelist";
import QcRejectedGt25SecUserList from "./components/qc-gt25secuserlist";
import QcRejectedGt25SecFiles from "./components/qc-gt25secfilelist";
import QcRejectedNoMatchSampleUserList from "./components/qc-notmatchsampleuserlist";
import QcRejectedNotMatchSampleFiles from "./components/qc-notmatchsamplefilelist";
import QcInterRejectedUserList from "./components/qc-interrejecteduserlist.jsx";
import QcInterRejectedFiles from "./components/qc-interrejectedfilelist.jsx";
import QcSegmentationUserList from "./components/qc-segmentation-userlist.jsx";
import QcSegmentationUserFileList from "./components/qc-segmentationuserfilelist.jsx";
import QcPrSegCompletedUserList from "./components/qcpr-seg-completed-userlist.jsx";
import QcPrSegCompletedUserFileList from "./components/qcpr-seg-completed-userfilelist.jsx";
import IntraUserList from "./components/intra-userlist";
import IntraUserFileList from "./components/intra-userfilelist";
import Intra2UserList from "./components/intra2-userlist";
import Intra2UserFileList from "./components/intra2-userfilelist";
import InterUserSampleFileList from "./components/inter-usersamplefilelist";
import Inter2UserSampleFileList from "./components/inter2-usersamplefilelist";
import CustomerFileList from "./components/customer-filelist";
import CustomerUserList from "./components/customer-userlist";
import AdminEndUserList from "./components/admin-enduser-list";
import AdminAllQCStatus from "./components/admin-all-qcstatus";
import AdminRevertStatus from "./components/admin-revertstatus";
import AdminDistrictUserList from "./components/admin-district-userlist";
import AdminDistrictUserFileList from "./components/admin-district-userfilelist";
import AdminLangApprovalList from "./components/admin-languageapproval-list";
import GgEditor from "./components/ggeditor";
import QcPrEditor from "./components/qcpr-editor";
//import VoiceVerify from "./components/VoiceVerify";

import jwt_decode from "jwt-decode";
import { logout } from "./actions/userActions";

function App() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  //console.log("userLogin", userLogin);

  useEffect(() => {
    if (userLogin.token) {
      const decoded = jwt_decode(userLogin.token);

      //check for expire token
      const currentTime = Date.now() / 1000; //to get milliseconds
      if (decoded.exp < currentTime) {
        //Logout user
        dispatch(logout());
      }
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Login} />
          {/* <Route exact path="/register" component={Register} /> */}
          <PrivateRoutes
            exact
            path="/audiorecordview"
            component={AudioRecordView}
          />
          <PrivateRoutes
            exact
            path="/coordinator-userlist"
            component={CoordinatorUserList}
          />
          <PrivateRoutes
            exact
            path="/coordinator-userfilelist"
            component={CoordinatorUserFileList}
          />
          <PrivateRoutes
            exact
            path="/coordinator-filelist"
            component={CoordinatorFileList}
          />
          <PrivateRoutes
            exact
            path="/admin-filelist"
            component={AdminFileList}
          />
          <PrivateRoutes
            exact
            path="/admin-bulkfilereject"
            component={AdminBulkFileReject}
          />
          <PrivateRoutes exact path="/image-upload" component={UploadImages} />
          <PrivateRoutes exact path="/addrate" component={AddRate} />
          <PrivateRoutes
            exact
            path="/admin-coordinator-list"
            component={AdminCoordinatorList}
          />
          <PrivateRoutes
            exact
            path="/admin-supervisor-list"
            component={AdminSupervisorList}
          />
          <PrivateRoutes
            exact
            path="/admin-qcuser-list"
            component={AdminQcUserList}
          />
          <PrivateRoutes
            exact
            path="/supervisor-filelist"
            component={SupervisorFileList}
          />
          <PrivateRoutes
            exact
            path="/supervisor-userlist"
            component={SupervisorUserList}
          />
          <PrivateRoutes
            exact
            path="/supervisor-userfilelist"
            component={SupervisorUserFileList}
          />
          <PrivateRoutes
            exact
            path="/supervisor-coordinator-list"
            component={SupervisorCoordinatorList}
          />
          <PrivateRoutes exact path="/admin-all-users" component={AllUsers} />
          <PrivateRoutes
            exact
            path="/admin-teamlead-list"
            component={AdminTeamleadList}
          />
          <PrivateRoutes
            exact
            path="/manager-filelist"
            component={ManagerFileList}
          />
          <PrivateRoutes
            exact
            path="/manager-userlist"
            component={ManagerUserList}
          />
          <PrivateRoutes
            exact
            path="/teamlead-filelist"
            component={TeamLeadFileList}
          />
          <PrivateRoutes
            exact
            path="/teamlead-coordinator-list"
            component={TeamLeadCoordinatorList}
          />
          <PrivateRoutes
            exact
            path="/teamlead-supervisor-list"
            component={TeamLeadSupervisorList}
          />
          <PrivateRoutes exact path="/qc-userlist" component={QcUserList} />
          <PrivateRoutes exact path="/qc-filelist" component={QcFileList} />
          <PrivateRoutes
            exact
            path="/qc-userfilelist"
            component={QcUserFileList}
          />
          <PrivateRoutes
            exact
            path="/qc-olduserlist"
            component={QcOldUserList}
          />
          <PrivateRoutes
            exact
            path="/qc-olduserfilelist"
            component={QcOldUserFileList}
          />
          <PrivateRoutes
            exact
            path="/qc-usersampleaudio"
            component={QcUserSampleFileList}
          />
          <PrivateRoutes
            exact
            path="/qc-interpaircheck"
            component={QcInterPairCheck}
          />
          <PrivateRoutes
            exact
            path="/qc-langmismatchuserfilelist"
            component={QcRejectedLangMismatchFiles}
          />
          <PrivateRoutes
            exact
            path="/qc-gendermismatchuserfilelist"
            component={QcRejectedGenderMismatchFiles}
          />
          <PrivateRoutes
            exact
            path="/qc-langmismatchuserlist"
            component={QcRejectedLangMismatchUserList}
          />
          <PrivateRoutes
            exact
            path="/qc-gendermismatchuserlist"
            component={QcRejectedGenderMismatchUserList}
          />
          <PrivateRoutes
            exact
            path="/qc-noisyuserlist"
            component={QcRejectedNoisyUserList}
          />
          <PrivateRoutes
            exact
            path="/qc-noisyfilelist"
            component={QcRejectedNoisyFiles}
          />
          <PrivateRoutes
            exact
            path="/qc-gt25secuserlist"
            component={QcRejectedGt25SecUserList}
          />
          <PrivateRoutes
            exact
            path="/qc-gt25secfilelist"
            component={QcRejectedGt25SecFiles}
          />
          <PrivateRoutes
            exact
            path="/qc-nomatchsampleuserlist"
            component={QcRejectedNoMatchSampleUserList}
          />
          <PrivateRoutes
            exact
            path="/qc-nomatchsamplefilelist"
            component={QcRejectedNotMatchSampleFiles}
          />
          <PrivateRoutes
            exact
            path="/qc-interrejecteduserlist"
            component={QcInterRejectedUserList}
          />
          <PrivateRoutes
            exact
            path="/qc-interrejectedfilelist"
            component={QcInterRejectedFiles}
          />
          <PrivateRoutes
            exact
            path="/qc-segmentation-userlist"
            component={QcSegmentationUserList}
          />
          <PrivateRoutes
            exact
            path="/qcpr-seg-completed-userlist"
            component={QcPrSegCompletedUserList}
          />
          <PrivateRoutes
            exact
            path="/qc-segmentationuserfilelist"
            component={QcSegmentationUserFileList}
          />
          <PrivateRoutes
            exact
            path="/qcpr-seg-completed-userfilelist"
            component={QcPrSegCompletedUserFileList}
          />
          <PrivateRoutes
            exact
            path="/intra-userlist"
            component={IntraUserList}
          />
          <PrivateRoutes
            exact
            path="/intra-userfilelist"
            component={IntraUserFileList}
          />
          <PrivateRoutes
            exact
            path="/intra2-userlist"
            component={Intra2UserList}
          />
          <PrivateRoutes
            exact
            path="/intra2-userfilelist"
            component={Intra2UserFileList}
          />
          <PrivateRoutes
            exact
            path="/inter-usersamplefilelist"
            component={InterUserSampleFileList}
          />
          <PrivateRoutes
            exact
            path="/inter2-usersamplefilelist"
            component={Inter2UserSampleFileList}
          />
          <PrivateRoutes
            exact
            path="/customer-filelist"
            component={CustomerFileList}
          />
          <PrivateRoutes
            exact
            path="/customer-userlist"
            component={CustomerUserList}
          />
          <PrivateRoutes
            exact
            path="/participant-list"
            component={AdminEndUserList}
          />
          <PrivateRoutes
            exact
            path="/admin-allqcstatus"
            component={AdminAllQCStatus}
          />
          <PrivateRoutes
            exact
            path="/admin-revertstatus"
            component={AdminRevertStatus}
          />
          <PrivateRoutes
            exact
            path="/admin-district-userlist"
            component={AdminDistrictUserList}
          />
          <PrivateRoutes
            exact
            path="/admin-district-userfilelist"
            component={AdminDistrictUserFileList}
          />
          <PrivateRoutes
            exact
            path="/admin-langapproval-list"
            component={AdminLangApprovalList}
          />
          <PrivateRoutes exact path="/gg_editor/:id" component={GgEditor} />
          <PrivateRoutes exact path="/qcpr_editor/:id" component={QcPrEditor} />
          {/* <Route exact path="/verifyvoice" component={VoiceVerify} /> */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
