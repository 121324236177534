import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from '../assets/images/Logo.svg';
import {MenuArrowRight, SignOut} from "./icons.component";
import { logout } from "../actions/userActions";
import { Link, Redirect, useHistory } from "react-router-dom";

import {AiOutlineCloseCircle} from "react-icons/ai"

const LeftNavigation = () => {
  const userLogin = useSelector((state) => state.userLogin);
  
  const dispatch = useDispatch();
  
  const Logout = () => {
    dispatch(logout());
  }

  const menuToggler = () => {
    const leftSideBar = document.querySelector(".leftSideBar")
    leftSideBar.style.display = "none"
  }

  if(userLogin.user.role)

    return (
      <>
        <div className="leftSideBar">
          <div>
            <img src={Logo} alt="Logo" className="LHSLogo" />
            <AiOutlineCloseCircle  className="closeMenu"
            onClick={() => menuToggler()}/>
            {userLogin.user.role == "Vendor" ? 
              (<ul className="navigationWrap">
              <li className="navItem">
              <Link to="/audiorecordview">
                <div className="navLabel">Home</div>
              </Link>
              </li>
            </ul>) 
             : userLogin.user.role == "Coordinator" ?
            (<ul className="navigationWrap">
              <li className="navItem">
              <Link to="/coordinator-userlist">
                <div className="navLabel">User List</div>
              </Link>
              </li>
              <li className="navItem">
              <Link to="/coordinator-filelist">
                <div className="navLabel">File List</div>
              </Link>
              </li>
            </ul>) 
             : userLogin.user.role == "Admin" ?
            (<ul className="navigationWrap">
            <li className="navItem">
            <Link to="/admin-filelist">
              <div className="navLabel">File List</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/admin-all-users">
              <div className="navLabel">Users</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/image-upload">
              <div className="navLabel">Image Upload</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/admin-bulkfilereject">
              <div className="navLabel">Bulk File Reject</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/admin-revertstatus">
              <div className="navLabel">Revert Status</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/admin-teamlead-list">
              <div className="navLabel">Team Lead-List</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/admin-supervisor-list">
              <div className="navLabel">Supervisor-List</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/admin-coordinator-list">
              <div className="navLabel">Coordinator-List</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/participant-list">
              <div className="navLabel">Participant-List</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/admin-qcuser-list">
              <div className="navLabel">QC List</div>
            </Link>
            </li>
            <li className="navItem">
            <Link to="/admin-langapproval-list">
              <div className="navLabel">Language Approval</div>
            </Link>
            </li>
            {/* <li className="navItem">
            <Link to="/admin-allqcstatus">
              <div className="navLabel">ALL-QC-Status</div>
            </Link> 
            </li>
            <li className="navItem">
            <Link to="/admin-district-userlist">
              <div className="navLabel">district-wise Users</div>
            </Link> 
            </li> */}

            </ul>) : userLogin.user.role == "Supervisor" ?
            (<ul className="navigationWrap">
               <li className="navItem">
               <Link to="/supervisor-userlist">
                 <div className="navLabel">User List</div>
               </Link>
               </li>
               <li className="navItem">
               <Link to="/supervisor-filelist">
                 <div className="navLabel">File List</div>
               </Link>
               </li>
               <li className="navItem">
               <Link to="/supervisor-coordinator-list">
                 <div className="navLabel">Coordinator-List</div>
               </Link>
               </li>
            </ul>) : userLogin.user.role == "Manager" ? 
            (<ul className="navigationWrap">
             <li className="navItem">
             <Link to="/manager-filelist">
               <div className="navLabel">File List</div>
             </Link>
             </li>
             <li className="navItem">
             <Link to="/manager-userlist">
               <div className="navLabel">User List</div>
             </Link>
             </li>
            </ul>) : userLogin.user.role == "TeamLead" ?
            (<ul className="navigationWrap">
           <li className="navItem">
           <Link to="/teamlead-filelist">
             <div className="navLabel">File List</div>
           </Link>
           </li>
           <li className="navItem">
           <Link to="/teamlead-supervisor-list">
             <div className="navLabel">Supervisor List</div>
           </Link>
           </li>
           <li className="navItem">
           <Link to="/teamlead-coordinator-list">
             <div className="navLabel">Coordinator List</div>
           </Link>
           </li>
            </ul>) : userLogin.user.role == "QualityChecker" ?
            (<ul className="navigationWrap">
            <li className="navItem">
              <Link to="/qc-userlist">
                <div className="navLabel">Regular QC Jobs</div>
              </Link>
            </li>
            <li className="navItem">
              <Link to="/qc-olduserlist">
                <div className="navLabel">Old QC Jobs</div>
              </Link>
            </li>
            <li className="navItem">
              <Link to="/qc-filelist">
                <div className="navLabel">File List</div>
              </Link>
            </li>
            <li className="navItem">
              <Link to="/qc-langmismatchuserlist">
                <div className="navLabel">Language Mismatch QC</div>
              </Link>
            </li>
            <li className="navItem">
              <Link to="/qc-gendermismatchuserlist">
                <div className="navLabel">Gender Mismatch QC</div>
              </Link>
            </li>
            <li className="navItem">
              <Link to="/qc-nomatchsampleuserlist">
                <div className="navLabel">Not Match With Sample QC</div>
              </Link>
            </li>
            <li className="navItem">
              <Link to="/qc-segmentation-userlist">
                <div className="navLabel">Segmentation Jobs</div>
              </Link>
            </li>
            {/*<li className="navItem">
              <Link to="/qc-noisyuserlist">
                <div className="navLabel">Background Noise QC</div>
              </Link>
            </li>
            <li className="navItem">
              <Link to="/qc-gt25secuserlist">
                <div className="navLabel">Greater than 25 Sec QC</div>
              </Link>
            </li>
            <li className="navItem">
              <Link to="/qc-interrejecteduserlist">
                <div className="navLabel">Inter Rejected QC</div>
              </Link>
            </li> */}
            {/* <li className="navItem">
              <Link to="/qc-langmismatchfiles">
                <div className="navLabel">Language Mismatch</div>
              </Link>
            </li> */}
            {/* <li className="navItem">
              <Link to="/qc-usersampleaudio">
                <div className="navLabel">User Samples</div>
              </Link>
            </li> */}
            {/* <li className="navItem">
              <Link to="/qc-interpaircheck">
                <div className="navLabel">Inter Pair Check</div>
              </Link>
            </li> */}
          </ul>) : userLogin.user.role == "Customer" ?	
              (<ul className="navigationWrap">	
              <li className="navItem">	
                <Link to="/customer-filelist">	
                  <div className="navLabel">File List</div>	
                </Link>	
              </li>	
              <li className="navItem">	
                <Link to="/customer-userlist">	
                  <div className="navLabel">Assign for transcription</div>	
                </Link>	
              </li>	
            </ul>) : userLogin.user.role == "QCPR" ?	
              (<ul className="navigationWrap">	
              <li className="navItem">	
                <Link to="/qcpr-seg-completed-userlist">	
                  <div className="navLabel">User List</div>	
                </Link>	
              </li>	
            </ul>) : ""
          //    userLogin.user.role == "Intra1" ? 
          //   (<ul className="navigationWrap">	
          //     <li className="navItem">	
          //       <Link to="/intra-userlist">	
          //         <div className="navLabel">User List</div>	
          //       </Link>	
          //     </li>
          //   </ul>): userLogin.user.role == "Intra2" ?
          //    (<ul className="navigationWrap">	
          //    <li className="navItem">
          //      <Link to="/intra2-userlist">	
          //        <div className="navLabel">User List</div>	
          //      </Link>	
          //    </li>
          //  </ul>) : userLogin.user.role == "Inter1" ? 
          //  (<ul className="navigationWrap">	
          //  <li className="navItem">
          //    <Link to="/inter-usersamplefilelist">	
          //      <div className="navLabel">User sample Files</div>	
          //    </Link>	
          //  </li>
          //  </ul>) : userLogin.user.role == "Inter2" ?
          //   (<ul className="navigationWrap">	
          //   <li className="navItem">
          //     <Link to="/inter2-usersamplefilelist">	
          //       <div className="navLabel">User sample Files</div>	
          //     </Link>	
          //   </li>
          //  </ul>)
            
          }
          </div>
          <div className="logoutLink" onClick={Logout}>
            <SignOut />
            <div className="navLabel">Sign Out</div>
          </div>
        
        </div>
      </>
    );
};

export default LeftNavigation;