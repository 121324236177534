import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import { Alert, CustomDialog, Confirm } from "react-st-modal";
import { format } from "date-fns";
import Table from "./Table/Table";
import Spinner from "./utils/spinner";


const UploadImages = () => {

  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    //resolver: yupResolver(schema)
  });

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [spinnerMessage, setSpinnerMessage] = useState("");
  const [upload, setUpload] = useState(false);
  const [folderPath, setFolderPath] = useState("");
  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [imageFileList, setImageFileList] = useState([]);
  const [imageData, setImageData] = useState([]);
  const acceptExtensions = ".jpg, .jpeg, .png"
  

  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("States", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);
  
  useEffect(() => {
      async function getDistricts() {
      const url = `${process.env.REACT_APP_URL}/getdistricts`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Districts", res.data.data)
          setDistricts(
            res.data.data.map((district) => ({ label: district, value: district }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getDistricts();
  }, [state]);

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
  }
  
  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);

    async function getimagefiles() {
      const url = `${process.env.REACT_APP_URL}/getallimagefiles`;
      const headers = reqHeader(true, true, true);
      const params = {state, district: e.target.value};
      //console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("States", res.data.data);
          const imgarray = []
          res.data.data.map((img)=>{
            imgarray.push(img.imgname);
          })
          setImageFileList(imgarray);
          setImageData(res.data.data);
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getimagefiles();

  }


  const handleSubmit = async (files, allFiles) => {
    allFiles.forEach((f) => f.remove());

    let file = files.map((f) => f.file);
    let meta = files.map((f) => f.meta);

    console.log("file", file)
    console.log("meta", meta)
    console.log("state, District", state, district)
    if (
      state.length == 0 ||
      district.length == 0 
    ) {
      toast.error("Please select State and District before submit!")
    }else{
      setSpinnerMessage("Uploading files... Please Wait!");
      setLoading(true);

      let existedFiles = [];
   
      for (let newFile of file) {
      
        let exists = imageFileList.indexOf(newFile.name);

        if (exists > -1) {
          existedFiles.push(newFile.name);
        }
      }

      if (existedFiles.length > 0) {
        toast.error(
          <div>
            The below files are already uploaded :-
            <br />
            {existedFiles.map((value) => {
              return (
                <span key={value}>
                  {value} <br />
                </span>
              );
            })}
          </div>
        );

        setLoading(false);
        setUpload(!upload);

        return;
      }else{
          await fileList(file, meta);
          toast.success(`File Uploaded Successfully !!!`);
          // setTimeout(()=>{
          //   window.location.reload()
          // },3000)
         
          setLoading(false);
          setUpload(!upload);
        }
    }
  };

  const fileList = async (files, metas) => {
    let counter = 0;

    for (let file of files) {
      try {
        //console.log("file", file.type, metas)
        // const lastDot = file.name.lastIndexOf(".")
        // const fileName = file.name.substring(0, lastDot);
        // const ext = file.name.substring(lastDot + 1);
        // newFileName = fileName.replace(/[^a-z\d]+/gi, "");

        const imgPath = process.env.REACT_APP_ENVIRONMENT == "production" ? "https://storage.googleapis.com/image-audio-recording/Images/" : "https://storage.googleapis.com/staging-image-audio-recording/Images/";

        const UserDistrct = district.replace(/[^a-z\d]+/gi, "");
        const UserState = state.replace(/[^a-z\d]+/gi, "");

        const imgLocation = imgPath + UserState + "_" + UserDistrct + "/" + file.name;

        //console.log("imgLocation", imgLocation)

         const url = `${process.env.REACT_APP_URL}/uploadimagefile`;
         const headers = reqHeader(true, true, true);

         const config = { headers };

        const body = {
          imgname: file.name,
          imgLocation: imgLocation,
          state: state,
          district: district,
          isavailable: true,
          fileType: file.type
        };

        //console.log("body", body);

        const { data } = await axios.post(url, body, config);

        //console.log("data", data)

        if (data) {
          const signedurl = data.data.url;
          //const urls = data.data.srcuploadpath;

          await sendFile(signedurl, files[counter]);
        }
      } catch (error) {}

      counter = counter + 1;
    }

    return counter;
  };

  const sendFile = (signedUrl, file) => {
    //console.log("signedUrl, file", signedUrl, file)
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(signedUrl);
          } else {
            reject({
              status: xhr.status,
              statusText: xhr.statusText,
            });
          }
        }
      };

      xhr.upload.onprogress = (evt) => {
        // For uploads
        if (evt.lengthComputable) {
          var percentComplete = ((evt.loaded / evt.total) * 100).toFixed(2);
          setProgress(percentComplete);
        }
      };

      xhr.onloadend = async function () {
        // setLoading(false);
        // toast.success(`File Uploaded Successfully !!!`);
        // setTimeout(() => {
        //   dialog.close(url);
        //   window.location.reload();
        // }, 2000);
      };

      xhr.open("PUT", signedUrl);
      xhr.setRequestHeader("Content-type", "application/octet-stream");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.send(file);
    });
  };

  const Preview = ({ meta }) => {
    const { name, percent, status } = meta
    return (
      <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
        {name}
      </span>
    )
  }


  let columns = useMemo(
    () => [
      {
        Header: "Image name",
        accessor: "imgname",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Uploaded On",
        accessor: "uploadedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.uploadedOn && format(
                    new Date(row.original.uploadedOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },

    ],
    []
  );


    return loading ? (
    <div>
      <Spinner spinneruploadtext={spinnerMessage} progress={progress} />
    </div>
    ) : (
      <>
      {/* <PopupFullPage /> */}
        <div className="container uploadPage d-flex">
          <LeftNavigation />
            <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Upload
              </div>

              <UserProfile />
            </div>
           
            <div className="uploadPageContent">
              <div className="projectContent block">
                <div className="d-flex justify-between">
                  <h1 className="pageTitle">Image Details</h1>
                  <div className="d-flex justify-end regionSelectWrap">
                  <div className="regionselect d-flex">
                  <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                          className="addRate"
                        >
                          {}
                           <option value="">State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error addRate"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">District</option>
                          {districts && districts.map((district)=>{
                            console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                </div>
                <div className="uploadFileWrap">
                    <Dropzone
                      onSubmit={handleSubmit}
                      autoUpload={false}
                      // maxFiles={1}
                      styles={{
                        dropzone: {
                          overflow: "auto",
                          background: "#D6F4F3",
                          border: "2px solid #34C6C3",
                          zIndex:"20",
                          display: "grid", 
                          gridTemplateColumns: "repeat(5,20%)"
                        },
                        inputLabelWithFiles: { display: "none" },
                        inputLabel: {
                          color: "#34C6C3",
                        },
                      }}
                      canRemove={true}
                      accept={acceptExtensions}
                      PreviewComponent={Preview}
                      inputContent={(files, extra) =>
                        extra.reject
                          ? "Image files only"
                          : "Drag & Drop files here or click to select the file"
                      }
                    />
                </div>
                <div className="adminTable"> 
                  <Table
                    columns={columns}
                    data={imageData}
                    count={imageData.length}
                    pagination={true}
                    expanded={false}
                    isHeader={true}
                    filter={true}
                    isCountDisplayed={true}
                    customText="Image List"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default UploadImages;